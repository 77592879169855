<template>
	<v-card class="party-activity" :height="413">
		<v-card-text class="pt-3 pb-1">
			<div class="d-sm-flex justify-space-between align-center">
				<h2 class="fs--23 fw--500 mr-2">Party Activity</h2>
				<v-tooltip top color="tooltipBg">
					<template v-slot:activator="{ on, attrs }">
						<div v-bind="attrs" v-on="on">
							<v-img width="40" src="@/assets/icons/info.png"></v-img>
						</div>
						
					</template>
					<strong class="offWhite--text">Party Activity since 11/16/2022</strong>
				</v-tooltip>
			</div>

			<v-row>
				<v-col cols="12" sm="6" class="d-flex flex-column align-start justify-center pt-15 pt-sm-15">
					<div>
						<strong class="offWhite--text fs--50 fw--800">{{
								Number(data.partyCount).toLocaleString()
						}}</strong>
						<p class="offWhite--text fs--20 fw--400">Sticker Parties</p>
					</div>
					<div>
						<strong class="offWhite--text fs--50 fw--800">{{ lastPartyDate }}</strong>
						<p class="offWhite--text fs--20 fw--400">Last Party</p>
					</div>
				</v-col>
			</v-row>
		</v-card-text>
		<img class="d-none d-sm-block"
			:class="[$vuetify.breakpoint.mdOnly && 'md-image', $vuetify.breakpoint.smOnly && 'sm-image']"
			src="@/assets/images/mascot/PartyActivity-Mascott.png" :height="$vuetify.breakpoint.mdOnly && '90%'"
			alt="" />
	</v-card>
</template>

<script>
import axios from '@axios';
import moment from 'moment';
import { computed, reactive } from '@vue/composition-api';
import { mdiHelp } from '@mdi/js'
export default {
	setup() {
		const data = reactive({
			partyCount: 0,
			lastPartyDate: null,
		});

		const lastPartyDate = computed(() => {
			if (data.lastPartyDate) {
				return moment(data.lastPartyDate).format('L');
			}
			return null;
		});

		const date = reactive({ range: [moment().subtract(1, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')] })

		const actions = {
			onDateChange: function (item) {
				date.range = []
				date.range.push(...item)

				actions.fetchData();
			},
			fetchData: function () {
				axios.get(`/insights/party-activity/${date.range[0]}/${date.range[1]}`).then(res => {
					Object.assign(data, res.data);
				});
			},
		};

		actions.fetchData();

		return {
			help: mdiHelp,
			data,
			actions,
			lastPartyDate,
			date
		};
	},
};
</script>

<style lang="scss" scoped>
.party-activity {
	position: relative;

	img {
		position: absolute;
		right: -46px;
		bottom: -35px;
		width: 400px;
	}

	img.md-image {
		width: 350px;
		right: -40px;
	}

	img.sm-image {
		width: 500px;
		right: -58px;
		bottom: -44px;
	}
}
</style>
