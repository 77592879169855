<template>
	<div class="popular-stickers">
		<v-card>
			<v-card-text class="pt-3 pb-1">
				<div class="d-sm-flex justify-space-between align-center">
					<div>
						<h2 class="fs--23 fw--500">Popular Stickers</h2>
					</div>
					<div class="d-flex">
						<v-menu offset-y :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="secondary"
									class="px-0 mr-2 mt-2 mt-sm-0"
									small
									dark
									v-bind="attrs"
									v-on="on">
									<template #default>
										<div class="d-flex align-center">
											<div class="text-center px-5 px-lg-5 px-xl-10">
												<span>Filter</span>
											</div>

											<v-divider vertical class="mx-0 mx-sm-2"></v-divider>
											<v-icon class="mr-2">
												{{ icons.arrowDown }}
											</v-icon>
										</div>
									</template>
								</v-btn>
							</template>
							<v-list dense color="secondary" class="pt-0 pb-4 px-0">
								<v-list-item>
									<v-checkbox
										v-model="filter.custom"
										@change="actions.onFilterChange"
										label="Custom"
										color="textColor"
										value="CUSTOM"
										hide-details></v-checkbox>
								</v-list-item>
								<v-list-item>
									<v-checkbox
										v-model="filter.reward"
										@change="actions.onFilterChange"
										label="Reward"
										color="textColor"
										value="REWARD"
										hide-details></v-checkbox>
								</v-list-item>
								<v-list-item>
									<v-checkbox
										v-model="filter.global"
										@change="actions.onFilterChange"
										label="Global"
										color="textColor"
										value="GLOBAL"
										hide-details></v-checkbox>
								</v-list-item>
							</v-list>
						</v-menu>
						<!-- Date Range -->
						<DateRange :range="date.range" span="year" @onDateRangeChange="actions.onDateChange" />
					</div>
				</div>
				
				<!-- <div class="d-flex justify-end mt-4 mt-sm-2">
					<span>Sort By:</span>
					<v-item-group v-model="sortBy" class="ml-4 px-0" active-class="secondary" mandatory>
						<v-item class="px-0" v-slot="{ active, toggle }">
							<v-btn
								small
								class="px-7"
								:outlined="!active"
								:color="active ? 'secondary' : 'fieldFore'"
								depressed
								@click="toggle">
								<span>Slaps</span>
							</v-btn>
						</v-item>
						<v-item v-slot="{ active, toggle }">
							<v-btn
								small
								class="ml-4 px-8"
								:color="active ? 'secondary' : 'fieldFore'"
								:outlined="!active"
								depressed
								@click="toggle">
								<span>Bits</span>
							</v-btn>
						</v-item>
					</v-item-group>
				</div> -->
				
				<v-data-iterator :items="stickers" :items-per-page.sync="itemsPerPage" :page.sync="page"
					hide-default-footer>
					<template v-slot:default="props">
						<v-row class="mt-3 mb-2">
							<v-col :cols="12 / itemsPerRow" v-for="sticker in props.items" :key="sticker.id">
								<v-card color="lightpurple popular-sticker" height="100%" flat>
									<v-card-text class="px-3 pt-2 pb-3">
										<div class="sticker-head mb-1">
											<div class="ribbon pink text-center">
												<span class="ml-6 fs--14 fw--600">{{ sticker.index + 1 }}</span>
											</div>
											<!-- <div class="badge">
												<v-badge color="pink" :content="40" class="mx-2" left></v-badge>
											</div> -->
											<div class="d-flex align-center justify-center mt-2 mx-3">
												<h4 class="fs--17 fw--300 sticker-name text-center">
													{{ sticker.name }}
												</h4>
											</div>
											<div class="text-center">
												<span class="fieldFore--text fs--12">{{
														nameFromStickerType(sticker.stickerCategory)
												}} Sticker</span>
											</div>
										</div>

										<div class="sticker-image d-flex align-center justify-center mb-5">
											<v-img width="100%" height="120" contain
												:src="actions.buildImageUrl(sticker)">
											</v-img>
										</div>
										
										<!-- <v-btn block outlined color="secondary" class="grid-btn"
											><span class="white--text text-capitalize">TODO - 5,200 Slaps</span></v-btn
										> -->
										
									</v-card-text>

									<v-btn outlined color="secondary" class="mt-2 action-btn"><img class="mr-1"
											width="20" :src="getBitIcon(sticker.bits)" />
										<span class="white--text">{{ Number(sticker.bits).toLocaleString() }}</span>
									</v-btn>
								</v-card>
							</v-col>
						</v-row>
					</template>
				</v-data-iterator>

				<div class="text-right mb-2">
					<span class="fs--14 fw--300">{{ paginateCaption }}</span>
					<v-icon class="mx-2" @click="prevPage" :disabled="page === 1">{{ icons.arrowLeft }}</v-icon>
					<v-icon @click="nextPage" :disabled="page === numberOfPages">{{ icons.arrowRight }}</v-icon>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import axios from '@axios';
import moment from 'moment';
import { reactive, ref, computed } from '@vue/composition-api';
import { mdiCalendar, mdiChevronLeft, mdiChevronDown, mdiChevronRight } from '@mdi/js';
import vuetify from '../../../plugins/vuetify';
import { getUserId } from '../../../utils/auth';
import { getGlobalStickerAssetPath, getCustomStickerAssetPath } from '../../../utils/config';
import { StickerType, nameFromStickerType } from '../../../utils/constants';
import { parseDate } from '../../../utils/utils';
import { getBitIcon } from '../../../utils/utils';
import DateRange from '@/views/shared/DateRange.vue';

export default {
	components: {
		DateRange,
	},
	setup() {
		const stickers = reactive([]);
		const date = reactive({
			range: [moment().subtract(1, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
		});

		const sortBy = ref(0);

		const numberOfPages = ref(0);
		const totalItems = ref(0);

		const filter = reactive({
			custom: StickerType.CUSTOM,
			reward: StickerType.REWARD,
			global: StickerType.GLOBAL,
			streamerOnline: false,
		});

		const actions = {
			onDateChange: function (item) {
				date.range = [];
				date.range.push(...item);
				actions.fetchData();
			},
			onFilterChange: function () {
				actions.fetchData();
			},
			fetchData: function () {
				const filters = [];
				if (filter.custom) filters.push(filter.custom);
				if (filter.reward) filters.push(filter.reward);
				if (filter.global) filters.push(filter.global);

				const filterString = filters.join(',');

				axios
					.get(`/insights/popular-stickers/${date.range[0]}/${date.range[1]}?filters=${filterString}`)
					.then(res => {
						stickers.length = 0;
						stickers.push(...res.data);

						totalItems.value = stickers.length;
						numberOfPages.value = Math.ceil(totalItems.value / itemsPerPage.value);
					});
			},
			buildImageUrl: sticker => {
				if (sticker.stickerCategory == StickerType.GLOBAL) {
					return getGlobalStickerAssetPath() + '/' + sticker.staticView;
				} else {
					return getCustomStickerAssetPath(getUserId()) + '/' + sticker.staticView;
				}
			},
		};

		const page = ref(1);

		const itemsPerRow = computed(() => {
			const breakpoint = vuetify.framework.breakpoint.name;
			switch (breakpoint) {
				case 'xs':
					return 1;
				case 'sm':
					return 3;
				case 'md':
					return 4;
				case 'lg':
					return 6;
				default:
					return 6;
			}
		});

		const rowsPerPage = computed(() => {
			const breakpoint = vuetify.framework.breakpoint.name;
			switch (breakpoint) {
				case 'xs':
					return 4;
				case 'sm':
					return 1;
				case 'md':
					return 1;
				case 'lg':
					return 1;
				case 'xl':
					return 1;
				default:
					return 1;
			}
		});

		const itemsPerPage = computed(() => {
			return Math.ceil(rowsPerPage.value * itemsPerRow.value);
		});

		const paginateCaption = computed(() => {
			if (totalItems.value > 0) {
				let end = page.value * itemsPerPage.value;
				const start = end - itemsPerPage.value + 1;
				if (end > totalItems.value) {
					end = totalItems.value;
				}
				return `${start} - ${end} of ${totalItems.value}`;
			} else {
				return 'No items';
			}
		});

		const nextPage = () => {
			if (page.value + 1 <= numberOfPages.value) page.value += 1;
		};

		const prevPage = () => {
			if (page.value - 1 >= 1) page.value -= 1;
		};

		// Load initial data.
		actions.fetchData();

		return {
			sortBy,
			actions,
			stickers,
			nameFromStickerType,
			date,
			page,
			numberOfPages,
			itemsPerPage,
			itemsPerRow,
			paginateCaption,
			nextPage,
			prevPage,
			parseDate,
			getBitIcon,
			filter,
			icons: {
				calendar: mdiCalendar,
				arrowLeft: mdiChevronLeft,
				arrowRight: mdiChevronRight,
				arrowDown: mdiChevronDown,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.popular-sticker {
	position: relative;
	overflow: hidden;

	.sticker-head {
		height: 80px;
		width: 100%;
		position: relative;

		.badge {
			position: absolute;
			left: 0%;
			top: 7px;
		}

		.ribbon {
			transform: rotate(-45deg);
			width: 100px;
			position: absolute;
			top: 0px;
			left: -55px;
		}
	}

	.sticker-image {
		height: 120px;
		margin-bottom: 50px !important;
	}

	.action-btn {
		position: absolute;
		width: 90%;
		bottom: 10px;
		left: 5%;
	}
}
</style>
