<template>
	<div>
		<page-title title="Insights" class="mb-6"></page-title>

		<v-row>
			<v-col cols="12" lg="7" xl="8" class="pr-lg-1">
				<activity-feed :key="bitsChangedKey"></activity-feed>
			</v-col>
			<v-col cols="12" lg="5" xl="4">
				<leaderboard :key="bitsChangedKey"></leaderboard>
			</v-col>
		</v-row>

		<v-row class="mb-4">
			<v-col cols="12" md="6" class="pr-lg-1">
				<sales-breakdown :key="bitsChangedKey" :insights="true"></sales-breakdown>
			</v-col>
			<v-col cols="12" md="6">
				<PartyActivity :key="partyModeStartKey" />
			</v-col>
			<v-col cols="12" class="mt-4">
				<PopularStickers :key="bitsChangedKey" />
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { onBeforeUnmount, ref } from '@vue/composition-api';
import { MessageEnum } from '../../utils/constants';
import PageTitle from '@/components/PageTitle.vue';
import ActivityFeed from './home/ActivityFeed.vue';
import Leaderboard from './home/LeaderBoard/Leaderboard.vue';
import SalesBreakdown from './home/SalesBreakdown.vue';
import PartyActivity from './insights/PartyActivity.vue';
import PopularStickers from './insights/PopularStickers.vue';

export default {
	components: {
		PageTitle,
		ActivityFeed,
		Leaderboard,
		SalesBreakdown,
		PartyActivity,
		PopularStickers,
	},
	setup() {
		// Set a key so we can refresh child components per web socket messages.
		const bitsChangedKey = ref(new Date().getTime());
		const partyModeStartKey = ref(new Date().getTime());

		// Handle WS events.
		const eventBus = require('js-event-bus')();
		eventBus.on('ws-message', function (payload) {
			if (payload.method == MessageEnum.BITS_TRANSACTION) {
				bitsChangedKey.value = new Date().getTime();
			} else if (payload.method == MessageEnum.PARTY_MODE_START) {
				partyModeStartKey.value = new Date().getTime();
			}
		});

		onBeforeUnmount(() => {
			eventBus.detachAll();
		});

		return { bitsChangedKey, partyModeStartKey };
	},
};
</script>
